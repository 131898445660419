(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/side-nav/assets/javascripts/skeletons/route-play-game-guide-fallback.js') >= 0) return;  svs.modules.push('/components/tipsen/side-nav/assets/javascripts/skeletons/route-play-game-guide-fallback.js');
"use strict";

const {
  SkeletonLine
} = svs.components.lbUi;
const RoutePlayGameGuideParagraphFallback = () => React.createElement("div", {
  className: "game-guide-paragraph-skeleton"
}, React.createElement(SkeletonLine, {
  className: "game-guide-paragraph-title-skeleton"
}), React.createElement(SkeletonLine, {
  className: "game-guide-paragraph-text-skeleton"
}), React.createElement(SkeletonLine, {
  className: "game-guide-paragraph-text-skeleton"
}), React.createElement(SkeletonLine, {
  className: "game-guide-paragraph-text-skeleton"
}), React.createElement(SkeletonLine, {
  className: "game-guide-paragraph-text-skeleton"
}), React.createElement(SkeletonLine, {
  className: "game-guide-paragraph-text-skeleton game-guide-paragraph-text-short-skeleton"
}));
const RoutePlayGameGuideFallback = () => React.createElement("div", {
  className: "game-guide-skeleton"
}, React.createElement(SkeletonLine, {
  className: "game-guide-main-title-skeleton"
}), React.createElement(RoutePlayGameGuideParagraphFallback, null), React.createElement(RoutePlayGameGuideParagraphFallback, null), React.createElement(RoutePlayGameGuideParagraphFallback, null));
setGlobal('svs.components.tipsen.sideNav.RoutePlayGameGuideFallback', RoutePlayGameGuideFallback);

 })(window);