(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/side-nav/assets/javascripts/skeletons/route-play-odds-fallback.js') >= 0) return;  svs.modules.push('/components/tipsen/side-nav/assets/javascripts/skeletons/route-play-odds-fallback.js');
"use strict";

const {
  SkeletonLine,
  Skeleton
} = svs.components.lbUi;
const RoutePlayOddsFallback = () => React.createElement("div", {
  className: "pg_route_play_odds_fallback_skeleton"
}, React.createElement(Skeleton, {
  className: "pg_route_play_odds_fallback_skeleton__table__headers",
  numCols: 4,
  numLines: 1
}), React.createElement(SkeletonLine, {
  className: "pg_route_play_odds_fallback_skeleton__line"
}));
setGlobal('svs.components.tipsen.sideNav.RoutePlayOddsFallback', RoutePlayOddsFallback);

 })(window);