(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/side-nav/assets/javascripts/lazy-odds.js') >= 0) return;  svs.modules.push('/components/tipsen/side-nav/assets/javascripts/lazy-odds.js');
"use strict";

const {
  tipsenLazy
} = svs.components.tipsen.tipsenLazy;
const {
  RoutePlayOddsFallback
} = svs.components.tipsen.sideNav;
const LazyOdds = tipsenLazy('/cl/tipsen/route-play-odds', () => svs.components.tipsen.routePlayOdds.RoutePlayOdds, {
  fallback: RoutePlayOddsFallback
});
setGlobal('svs.components.tipsen.sideNav.LazyOdds', LazyOdds);

 })(window);