(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/side-nav/assets/javascripts/lazy-game-guide.js') >= 0) return;  svs.modules.push('/components/tipsen/side-nav/assets/javascripts/lazy-game-guide.js');
"use strict";

const {
  tipsenLazy
} = svs.components.tipsen.tipsenLazy;
const {
  RoutePlayGameGuideFallback
} = svs.components.tipsen.sideNav;
const LazyGameGuide = tipsenLazy('/cl/tipsen/route-play-game-guide', () => svs.components.tipsen.routePlayGameGuide.RoutePlayGameGuide, {
  fallback: RoutePlayGameGuideFallback
});
setGlobal('svs.components.tipsen.sideNav.LazyGameGuide', LazyGameGuide);

 })(window);