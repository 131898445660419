(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/side-nav/assets/javascripts/skeletons/route-play-analysis-fallback.js') >= 0) return;  svs.modules.push('/components/tipsen/side-nav/assets/javascripts/skeletons/route-play-analysis-fallback.js');
"use strict";

const {
  SkeletonLine,
  SkeletonCircle
} = svs.components.lbUi;
const RoutePlayAnalysisRowFallback = () => React.createElement("div", {
  className: "coupon-analysis-match-skeleton"
}, React.createElement(SkeletonLine, {
  className: "coupon-analysis-match-number-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-analysis-match-team-skeleton coupon-analysis-match-teamone-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-analysis-match-team-skeleton coupon-analysis-match-teamtwo-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-analysis-match-name-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-analysis-match-sign-skeleton coupon-analysis-match-signone-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-analysis-match-sign-skeleton coupon-analysis-match-signtwo-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-analysis-match-sign-skeleton coupon-analysis-match-signthree-skeleton"
}));
const RoutePlayAnalysisFallback = () => React.createElement(React.Fragment, null, React.createElement("div", {
  className: "coupon-analysis-skeleton"
}, React.createElement(SkeletonCircle, {
  className: "coupon-analysis-photo-skeleton",
  size: 44
}), React.createElement(SkeletonLine, {
  className: "coupon-analysis-name-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-analysis-checkbox-skeleton"
})), React.createElement("div", {
  className: "coupon-analysis-matches-skeleton"
}, React.createElement(RoutePlayAnalysisRowFallback, null), React.createElement(RoutePlayAnalysisRowFallback, null), React.createElement(RoutePlayAnalysisRowFallback, null), React.createElement(RoutePlayAnalysisRowFallback, null), React.createElement(RoutePlayAnalysisRowFallback, null), React.createElement(RoutePlayAnalysisRowFallback, null), React.createElement(RoutePlayAnalysisRowFallback, null), React.createElement(RoutePlayAnalysisRowFallback, null)));
setGlobal('svs.components.tipsen.sideNav.RoutePlayAnalysisFallback', RoutePlayAnalysisFallback);

 })(window);