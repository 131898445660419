(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/side-nav/assets/javascripts/skeletons/route-play-overview-fallback.js') >= 0) return;  svs.modules.push('/components/tipsen/side-nav/assets/javascripts/skeletons/route-play-overview-fallback.js');
"use strict";

const {
  SkeletonLine,
  SkeletonCircle
} = svs.components.lbUi;
const RoutePlayOverviewRowFallback = () => React.createElement("div", {
  className: "coupon-overview-row-skeleton"
}, React.createElement(SkeletonCircle, {
  className: "coupon-overview-number-skeleton",
  size: 18
}), React.createElement(SkeletonLine, {
  className: "coupon-overview-team-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-overview-team-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-overview-sign-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-overview-sign-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-overview-sign-skeleton"
}));
const RoutePlayOverviewFallback = () => React.createElement("div", {
  className: "coupon-overview-skeleton"
}, React.createElement(RoutePlayOverviewRowFallback, null), React.createElement(RoutePlayOverviewRowFallback, null), React.createElement(RoutePlayOverviewRowFallback, null), React.createElement(RoutePlayOverviewRowFallback, null), React.createElement(RoutePlayOverviewRowFallback, null), React.createElement(RoutePlayOverviewRowFallback, null), React.createElement(RoutePlayOverviewRowFallback, null), React.createElement(RoutePlayOverviewRowFallback, null), React.createElement("div", {
  className: "coupon-overview-footer-infos"
}, React.createElement(SkeletonLine, {
  className: "coupon-overview-footer-info-title-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-overview-footer-info-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-overview-footer-info-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-overview-footer-info-price-skeleton"
})));
setGlobal('svs.components.tipsen.sideNav.RoutePlayOverviewFallback', RoutePlayOverviewFallback);

 })(window);