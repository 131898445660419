(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/side-nav/assets/javascripts/side-nav.js') >= 0) return;  svs.modules.push('/components/tipsen/side-nav/assets/javascripts/side-nav.js');
"use strict";

function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
const {
  Switch,
  Route
} = ReactRouterDOM;
const {
  ReactPaper
} = svs.ui;
const {
  ItemsMenu
} = svs.ui.ReactItemsMenu;
const {
  useBranding
} = svs.components.tipsen.hooks;
const {
  ErrorBoundary
} = svs.components.errorBoundary;
const {
  useEffect
} = React;
const {
  TipsenLink
} = svs.components.tipsen.tipsen_link;
const {
  LinkItemRoute
} = svs.components.lbUtils.linkItemRoute;
const onResize = svs.components.tipsen.sideNavResize.onResize;
const SideNav = _ref => {
  let {
    children,
    menuItems,
    match
  } = _ref;
  const sideNavOverflowClass = 'tipsen-side-nav-overflow';
  const branding = useBranding();
  const itemsMenuClassNames = ['tipsen-side-nav-menu'];
  if (branding.length > 1) {
    itemsMenuClassNames.push(branding[branding.length - 1]);
  }
  const [productBranding] = branding;
  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);
  return React.createElement(ErrorBoundary, null, React.createElement(ReactPaper, {
    branding: "sport",
    className: "tipsen-side-nav"
  }, React.createElement(ItemsMenu, {
    branding: "side-nav",
    className: itemsMenuClassNames.join(' ')
  }, menuItems.map(_ref2 => {
    let {
      icon,
      to,
      label,
      selectedOnPaths,
      testIdSuffix
    } = _ref2;
    return React.createElement(LinkItemRoute, {
      Component: TipsenLink,
      "data-testid": "side-nav-menu-".concat(testIdSuffix),
      icon: icon,
      iconPosition: "left",
      key: to.pathname,
      label: label,
      selectedOnPaths: selectedOnPaths,
      to: to
    });
  })), React.createElement(Switch, null, menuItems.filter(_ref3 => {
    let {
      to
    } = _ref3;
    return to.pathname.length > 0;
  }).map(_ref4 => {
    let {
      to,
      className,
      Component,
      props = {}
    } = _ref4;
    let absolutePath;
    if (match.path === '/') {
      absolutePath = to.pathname;
    } else {
      absolutePath = "".concat(match.path).concat(to.pathname);
    }
    return React.createElement(Route, {
      key: to.pathname,
      path: absolutePath
      ,
      render: () => React.createElement(Component, _extends({
        branding: productBranding,
        className: [sideNavOverflowClass].concat(className ? [className] : []).join(' ')
      }, props))
    });
  }), children)));
};
setGlobal('svs.components.tipsen.sideNav.SideNav', SideNav);

 })(window);